<template>
  <Modal @close="$emit('close')" classes="max-w-3xl">
    <h1 class="heading font-bold mb-6">
      Reassign<span v-if="opportunityIds.length < 2">&nbsp;Intro</span>
      <span v-else>&nbsp;Intros</span>.
    </h1>

    <LoadingSpinner v-if="!friendlies.length" />
    <template v-else>
      <VueMultiselect
        class="w-48"
        v-model="selection"
        :options="friendlies"
        :close-on-select="true"
        :multiple="false"
        :showLabels="false"
        :disabled="attemptingReassignment"
        label="fullName"
        trackBy="id"
      />

      <div class="text-right mt-8">
        <Button
          :class="{ 'cursor-wait': attemptingReassignment }"
          :disabled="!selection || attemptingReassignment"
          @click.prevent="attemptReassignment()"
        >
          Reassign
        </Button>
      </div>
    </template>
  </Modal>
  <GlobalLoading v-if="attemptingReassignment" />
</template>

<script>
import _ from 'lodash'
import VueMultiselect from 'vue-multiselect'

import User from '@/services/users'
import Opportunity from '@/services/opportunities'

import Modal from '@/components/modals/Modal'
import LoadingSpinner from '@/components/LoadingSpinner'
import Button from '@/components/inputs/Button'
import GlobalLoading from '@/components/GlobalLoading'

export default {
  name: 'ReassignIntrosModal',
  components: {
    Modal,
    LoadingSpinner,
    VueMultiselect,
    Button,
    GlobalLoading,
  },
  props: {
    opportunityIds: {
      type: Array,
    },
  },
  data() {
    return {
      friendlies: [],
      selection: null,
      attemptingReassignment: false,
    }
  },
  emits: ['close'],
  async created() {
    this.friendlies = await User.api.listFriendlies()
  },
  methods: {
    attemptReassignment() {
      this.attemptingReassignment = true
      Opportunity.api
        .bulkAssignFriendly(this.opportunityIds, this.selection.id)
        .then(() => window.location.reload())
        .finally(() => (this.attemptingReassignment = false))
    },
  },
}
</script>

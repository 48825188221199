<template>
  <div class="flex items-center">
    <Button primary :disabled="disabled" @click="show = true"> Preview, Send </Button>
    <PreviewEmailsModal
      v-if="show"
      @close="show = false"
      :opportunities="opportunities"
      @cta-click="attemptSendEmails()"
    />
  </div>
</template>

<script>
import Opportunity from '@/services/opportunities'

import PreviewEmailsModal from '@/components/modals/PreviewEmailsModal'
import Button from '@/components/inputs/Button'

export default {
  name: 'PreviewSendEmails',
  components: {
    PreviewEmailsModal,
    Button,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    opportunities: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every((opportunity) => opportunity instanceof Opportunity)
      },
    },
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    async attemptSendEmails() {
      const confirmation = confirm('Confirm action: Send Emails.')
      if (!confirmation) return

      const promises = this.opportunities.map((o) =>
        Opportunity.api.sendEmail(
          o.id,
          o.friendlyIntroEmailTemplate.subject,
          o.friendlyIntroEmailTemplate.body,
        ),
      )
      await Promise.all(promises)
      this.$emit('sent')
    },
  },
}
</script>

<template>
  <Modal @close="$emit('close')" classes="max-w-5xl">
    <div class="flex flex-col items-start h-full pb-3">
      <h1 class="heading font-bold self-center">Edit Email.</h1>
      <LoadingSpinner v-if="loading" class="w-full" />
      <template v-else>
        <div class="w-full mb-6 text-left">
          <InputField
            class="mb-8"
            v-model:value="subject"
            :errors="subject.length ? [] : [{ message: 'Required' }]"
            label="Subject Line"
            placeholder="Enter subject line..."
          />
          <EmailTemplateEditor
            v-model:value="body"
            v-model:errors="editorErrors"
            v-model:activeTemplateVariables="activeEmailTemplateVariables"
            :validTemplateVariables="validEmailTemplateVariables"
          />
        </div>
        <div class="w-full flex justify-center items-center space-x-5">
          <button @click="$emit('close')">Cancel</button>
          <Button primary :disabled="compositionErrorsPresent" @click="emitCTA()">
            {{ ctaText }}
          </Button>
        </div>
      </template>
    </div>
  </Modal>
</template>

<script>
import OpportunityEmailTemplate from '@/services/opportunityEmailTemplates'

import Modal from '@/components/modals/Modal'
import LoadingSpinner from '@/components/LoadingSpinner'
import EmailTemplateEditor from '@/components/EmailTemplateEditor'
import InputField from '@/components/inputs/InputField'
import Button from '@/components/inputs/Button'

export default {
  name: 'EditEmailTemplateModal',
  components: {
    Modal,
    LoadingSpinner,
    EmailTemplateEditor,
    InputField,
    Button,
  },
  props: {
    ctaText: {
      type: String,
      default: 'Save',
    },
    template: {
      type: OpportunityEmailTemplate,
      required: true,
    },
    admin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      subject: this.template.subject,
      body: this.template.body,
      editorErrors: [],
      validEmailTemplateVariables: [],
      activeEmailTemplateVariables: [],
    }
  },
  async created() {
    this.validEmailTemplateVariables = await OpportunityEmailTemplate.api.retrieveVariableNames()

    if (!this.admin && this.template.id) {
      this.body = await OpportunityEmailTemplate.api.templateBodyWithFriendlyValues(
        this.template.id,
      )
    }

    this.loading = false
  },
  methods: {
    emitCTA() {
      const data = {
        subject: this.subject,
        body: this.body,
        variables: this.activeEmailTemplateVariables,
      }

      this.$emit('cta-click', data)
    },
  },
  computed: {
    compositionErrorsPresent() {
      return !!this.editorErrors.length || !this.subject.length || !this.body.length
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

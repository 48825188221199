<template>
  <div class="flex flex-col items-center">
    <h1 class="heading font-bold">Intro Requests.</h1>
    <br />
    <LoadingSpinner v-if="loading" />
    <div v-else-if="!lists.length" class="pt-10">
      <div class="text-9xl">🎉</div>
      <div class="text-xl">No outstanding intro requests</div>
    </div>
    <Tabs v-else :tabs="tabs" :currentTabId="activeListId" scroll @select="handleTabChange">
      <div class="flex flex-row mt-8 justify-between ml-6">
        <div>
          <Button
            class="mx-2"
            :disabled="!checkedIds.length || ongoingAction"
            @click="attemptDeclineIntros"
          >
            Can't Make Intro<span :class="{ invisible: checkedIds.length < 2 }">s</span>
          </Button>
          <Button
            v-if="!this.$store.getters.user.isAdmin"
            class="mx-2"
            :disabled="!checkedIds.length || ongoingAction"
            @click="attemptFriendlyDoesNotKnow"
          >
            Don't Know
            <span v-if="checkedIds.length < 2">&nbsp;This Person</span>
            <span v-else>&nbsp;These People</span>
          </Button>
          <Button
            class="mx-2"
            :disabled="!checkedIds.length || ongoingAction"
            @click="attemptConnectExternally"
          >
            Connect Externally
          </Button>
          <Button
            v-if="$store.getters.user.isFirstRoundPartner"
            class="mx-2"
            :disabled="!checkedIds.length || ongoingAction"
            @click="attemptOffloadAsGmailDrafts"
          >
            Offload to Gmail (Draft)
          </Button>
          <template v-if="this.$store.getters.user.isAdmin">
            <Button
              class="ml-2"
              :disabled="!checkedIds.length || ongoingAction"
              @click="showReassignIntrosModal = true"
            >
              Reassign
              <span v-if="checkedIds.length < 2">&nbsp;Intro</span>
              <span v-else>&nbsp;Intros</span>
            </Button>
            <Popper arrow placement="left-end">
              <InformationCircleIcon class="h-5 w-5 cursor-pointer" />
              <template #content>
                <div class="bg-white rounded-lg p-5 border max-w-prose">
                  Reassign to a better point of contact
                </div>
              </template>
            </Popper>
          </template>
        </div>
        <div class="flex flex-row">
          <PreviewSendEmails
            class="mr-4"
            :disabled="!checkedIds.length || ongoingAction"
            :opportunities="selectedOpportunities"
            @sent="reload"
          />
          <EditPreviewSendEmails
            :disabled="!allSelectedUseSameEmailTemplate || ongoingAction"
            :template="singleActiveEmailTemplate"
            :opportunities="selectedOpportunities"
            @sent="reload"
          />
          <Popper arrow placement="left-end">
            <InformationCircleIcon class="h-5 w-5 cursor-pointer" />
            <template #content>
              <div class="bg-white rounded-lg p-5 border max-w-prose">
                You can bulk edit only if all selections use the same template.
              </div>
            </template>
          </Popper>
        </div>
      </div>
      <table
        v-if="activeListOpportunities.length"
        class="w-full table-auto border-collapse border-0 border-slate-400 text-sm mt-7 mb-7"
      >
        <thead>
          <tr class="h-14">
            <th class="w-8">
              <CheckboxField v-model:value="checkAll" :disabled="ongoingAction" />
            </th>
            <th class="border border-slate-300">Name</th>
            <th class="border border-slate-300">Title</th>
            <th class="border border-slate-300">Company Name</th>
            <th class="border border-slate-300">
              Email Address
              <Popper arrow placement="left-end">
                <InformationCircleIcon class="h-5 w-5 cursor-pointer" />
                <template #content>
                  <div class="bg-white rounded-lg p-5 border max-w-prose font-normal text-left">
                    May be missing (from Salesforce). <br />
                    Set an email address if known. <br />
                    Else use <span class="italic">Can't Make Intro</span> or
                    <span class="italic">Connect Externally</span>.
                  </div>
                </template>
              </Popper>
            </th>
            <th class="border border-slate-300">Email Template</th>
            <th class="border border-slate-300" v-if="this.$store.getters.user.isAdmin">
              Assigned Friendly
            </th>
            <th class="border-none"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="opportunity in activeListOpportunities" :key="opportunity.id" class="h-10">
            <td>
              <CheckboxField
                v-if="opportunity.contactEmail"
                v-model:value="checkboxes[opportunity.id]"
                :disabled="ongoingAction"
              />
            </td>
            <td
              class="border border-slate-300 px-2"
              :class="{ 'bg-slate-200': !opportunity.contactEmail }"
            >
              <a
                v-if="opportunity.contactLinkedinProfile"
                :href="opportunity.contactLinkedinProfile"
                target="_blank"
                class="text-cyan-500"
              >
                {{ opportunity.contactName }}
              </a>
              <template v-else>{{ opportunity.contactName }}</template>
            </td>
            <td
              class="border border-slate-300 px-2"
              :class="{ 'bg-slate-200': !opportunity.contactEmail }"
            >
              {{ opportunity.contactTitle }}
            </td>
            <td
              class="border border-slate-300 px-2"
              :class="{ 'bg-slate-200': !opportunity.contactEmail }"
            >
              {{ opportunity.contactCompanyName }}
            </td>
            <ActionTableCell v-if="opportunity.contactEmail" class="break-all">
              {{ opportunity.contactEmail }}
              <template v-slot:actions>
                <OpportunityUpdateEmailAddress
                  :opportunity="opportunity"
                  @update-opportunity="updateOpportunity"
                />
              </template>
            </ActionTableCell>
            <td v-else class="border border-slate-300 bg-orange-300">
              <div class="flex flex-row justify-center">
                <OpportunityMissingEmailAddress
                  :opportunity="opportunity"
                  @update-opportunity="updateOpportunity"
                  @remove-opportunity="reload"
                />
              </div>
            </td>
            <ActionTableCell :class="{ 'bg-slate-200': !opportunity.contactEmail }">
              {{ opportunity.friendlyIntroEmailTemplate.name }}
              <template v-slot:actions>
                <EditOpportunityEmailTemplate
                  :opportunity="opportunity"
                  @update-opportunity="updateOpportunity"
                />
              </template>
            </ActionTableCell>
            <td class="border border-slate-300 px-2" v-if="this.$store.getters.user.isAdmin">
              {{ opportunity.friendly?.fullName }}
            </td>
            <td class="border-none text-right">
              <button class="w-16" @click.prevent="openNotesModal(opportunity)">
                {{ opportunity.friendlyNotes ? 'Edit Note' : 'Add Note' }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <LoadingSpinner v-else />
      <div class="flex justify-center mb-7" :class="{ hidden: !activeListOpportunities.length }">
        <Pagination
          pluralItemName="intros"
          :page="activeListPage"
          :pageSize="activeListPageSize"
          :totalCount="activeList.numOutstanding"
          @prev-page="retrievePageOfListOpportunities(activeListPage - 1)"
          @next-page="retrievePageOfListOpportunities(activeListPage + 1)"
        />
      </div>
    </Tabs>
  </div>
  <AddOpportunityNotesModal
    v-if="notesModalOpen"
    field="friendlyNotes"
    :opportunity="opportunityForNotes"
    @close="notesModalOpen = false"
    @update="updateOpportunity"
  />
  <ReassignIntrosModal
    v-if="showReassignIntrosModal"
    :opportunityIds="checkedIds"
    @close="showReassignIntrosModal = false"
  />
  <GlobalLoading v-if="ongoingAction" />
</template>

<script>
import Opportunity from '@/services/opportunities'
import CustomerList from '@/services/customerLists'
import { hasPage } from '@/services/pagination'

import { InformationCircleIcon, PencilSquareIcon } from '@heroicons/vue/24/solid'
import { PlusCircleIcon } from '@heroicons/vue/24/outline'
import LoadingSpinner from '@/components/LoadingSpinner'
import ActionTableCell from '@/components/ActionTableCell'
import CheckboxField from '@/components/inputs/CheckboxField'
import Button from '@/components/inputs/Button'
import EditOpportunityEmailTemplate from '@/components/inputs/EditOpportunityEmailTemplate'
import OpportunityMissingEmailAddress from '@/components/inputs/OpportunityMissingEmailAddress'
import OpportunityUpdateEmailAddress from '@/components/inputs/OpportunityUpdateEmailAddress'
import EditPreviewSendEmails from '@/components/inputs/EditPreviewSendEmails'
import PreviewSendEmails from '@/components/inputs/PreviewSendEmails'
import Tabs from '@/components/Tabs'
import AddOpportunityNotesModal from '@/components/modals/AddOpportunityNotesModal'
import Pagination from '@/components/inputs/Pagination'
import ReassignIntrosModal from '@/components/modals/ReassignIntrosModal'
import GlobalLoading from '@/components/GlobalLoading'

/**
 * If the object property does not exist, return false
 */
const checkboxDataHandler = {
  get: function (target, name) {
    // eslint-disable-next-line no-prototype-builtins
    return target.hasOwnProperty(name) ? target[name] : false
  },
}

export default {
  name: 'FriendlyIntroRequests',
  components: {
    InformationCircleIcon,
    PencilSquareIcon,
    PlusCircleIcon,
    LoadingSpinner,
    ActionTableCell,
    CheckboxField,
    Button,
    EditOpportunityEmailTemplate,
    EditPreviewSendEmails,
    PreviewSendEmails,
    Tabs,
    AddOpportunityNotesModal,
    OpportunityMissingEmailAddress,
    OpportunityUpdateEmailAddress,
    Pagination,
    ReassignIntrosModal,
    GlobalLoading,
  },
  data() {
    return {
      // active list state
      activeListPage: parseInt(this.$route.query.page) || 1,
      activeListPageSize: parseInt(this.$route.query.pageSize) || 20,
      activeListId: null, // set in created()
      activeListOpportunities: [],
      // standard state
      loading: true,
      lists: [],
      checkAll: false,
      checkboxes: new Proxy({}, checkboxDataHandler),
      notesModalOpen: false,
      ongoingAction: false,
      showReassignIntrosModal: false,
    }
  },
  async created() {
    this.lists = await CustomerList.api.withOutstandingIntrosForUser()
    // allow deeplinking to a specific list
    const listFromQueryParam = this.lists.find((l) => l.id == this.$route.query.listId)
    if (listFromQueryParam) {
      // ensure that list is set as 0th list
      const withoutListFromQueryParams = this.lists.filter((l) => l.id !== listFromQueryParam.id)
      this.lists = [listFromQueryParam, ...withoutListFromQueryParams]
      this.activeListId = listFromQueryParam.id
    } else {
      // else default to 0th list
      this.activeListId = this.lists[0]?.id || null
    }
    this.loading = false
    this.setListQueryParams()
  },
  methods: {
    handleTabChange(tab) {
      // set active list
      this.activeListId = tab.id
      // reset checkboxes
      this.checkAll = false
      // checkAll may have already been false, can't assume watcher triggers
      this.checkboxes = new Proxy({}, checkboxDataHandler)
    },
    reload() {
      window.location.reload()
    },
    validateNotes() {
      return this.selectedOpportunities.every((opportunity) => {
        return opportunity.friendlyNotes && opportunity.friendlyNotes.trim().length > 0
      })
    },
    attemptDeclineIntros() {
      if (!this.validateNotes()) {
        alert('You must add a note for each selected opportunity before declining.')
        return
      }
      const confirmation = confirm("Confirm action: Can't make intros.")
      if (!confirmation) return

      this.ongoingAction = true

      const data = { friendlyDeclined: true }
      if (this.$store.getters.user.isAdmin) data.adminDeclined = true

      Opportunity.api
        .bulkUpdate(this.checkedIds, data)
        .then(this.reload)
        .finally(() => (this.ongoingAction = false))
    },
    attemptFriendlyDoesNotKnow() {
      const confirmation = confirm("Confirm action: Don't Know These People.")
      if (!confirmation) return

      this.ongoingAction = true

      const data = { friendlyDoesNotKnow: true }

      Opportunity.api
        .bulkUpdate(this.checkedIds, data)
        .then(this.reload)
        .finally(() => (this.ongoingAction = false))
    },
    attemptConnectExternally() {
      const confirmation = confirm('Confirm action: Connect Externally.')
      if (!confirmation) return

      this.ongoingAction = true

      const data = {}
      if (this.$store.getters.user.isAdmin) data.adminIntroSent = true
      if (this.$store.getters.user.isFriendly) data.friendlyIntroSent = true

      Opportunity.api
        .bulkUpdate(this.checkedIds, data)
        .then(this.reload)
        .finally(() => (this.ongoingAction = false))
    },
    attemptOffloadAsGmailDrafts() {
      const confirmation = confirm('Confirm action: Offload to Gmail (Draft).')
      if (!confirmation) return

      this.ongoingAction = true

      const promises = this.checkedIds.map((id) => Opportunity.api.offloadAsGmailDraft(id))
      Promise.all(promises)
        .then(this.reload)
        .finally(() => (this.ongoingAction = false))
    },
    openNotesModal(opportunity) {
      this.opportunityForNotes = new Opportunity(JSON.parse(JSON.stringify(opportunity)))
      this.notesModalOpen = true
    },
    updateOpportunity(updatedOpportunity) {
      const updatedOpportunitiesList = this.activeListOpportunities.map((o) =>
        o.id === updatedOpportunity.id ? updatedOpportunity : o,
      )
      this.activeListOpportunities = updatedOpportunitiesList
    },
    retrievePageOfListOpportunities(page) {
      this.activeListOpportunities = []
      Opportunity.api
        .outstandingInListForUser(this.activeListId, page, this.activeListPageSize)
        .then((opportunities) => {
          this.activeListOpportunities = opportunities
          this.activeListPage = page
          this.setListQueryParams()
        })
    },
    setListQueryParams() {
      // since it is the same route, does not re-render view
      this.$router.push({
        name: this.$route.name,
        query: {
          listId: this.activeListId,
          page: this.activeListPage,
          pageSize: this.activeListPageSize,
        },
      })
    },
  },
  computed: {
    tabs() {
      return this.lists.map((l) => ({
        id: l.id,
        label: l.name,
        badge: l.numOutstanding,
      }))
    },
    activeList() {
      return this.lists.find((l) => l.id === this.activeListId)
    },
    checkedIds() {
      return Object.entries(this.checkboxes)
        .filter((entry) => entry[1])
        .map((entry) => Number(entry[0]))
    },
    selectedOpportunities() {
      return this.activeListOpportunities.filter((o) => this.checkedIds.includes(o.id))
    },
    allSelectedUseSameEmailTemplate() {
      const templateIds = new Set()
      this.selectedOpportunities.forEach((o) => {
        if (o.friendlyIntroEmailTemplate) {
          templateIds.add(o.friendlyIntroEmailTemplate.id)
        }
      })

      return this.selectedOpportunities.length > 0 && templateIds.size === 1
    },
    singleActiveEmailTemplate() {
      if (this.allSelectedUseSameEmailTemplate)
        return this.selectedOpportunities[0].friendlyIntroEmailTemplate
      return null
    },
  },
  watch: {
    checkAll(value) {
      const newCheckboxes = new Proxy({}, checkboxDataHandler)
      if (!value) {
        this.checkboxes = newCheckboxes
        return
      }

      // cannot select those without a contact email
      this.activeListOpportunities
        .filter((o) => o.contactEmail)
        .forEach((o) => {
          newCheckboxes[o.id] = true
        })

      this.checkboxes = newCheckboxes
    },
    activeListId(listId, prevListId) {
      this.activeListOpportunities = []
      // if there is a previous list, then reset page to 1
      // if there is not a previous list, allow deeplink into a specific page
      // if deeplinking, ensure the deeplink's page is within valid range, else set to 1
      if (
        prevListId ||
        !hasPage(this.activeListPage, this.activeListPageSize, this.activeList.numOutstanding)
      ) {
        this.activeListPage = 1
      }
      this.setListQueryParams()
      Opportunity.api
        .outstandingInListForUser(listId, this.activeListPage, this.activeListPageSize)
        .then((opportunities) => {
          this.activeListOpportunities = opportunities
        })
    },
  },
}
</script>

<style scoped>
th,
td {
  padding: 0.5rem;
}
</style>

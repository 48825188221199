import { ModelAPI, ApiFilter } from '@thinknimble/tn-models'
import { objectToCamelCase, objectToSnakeCase } from '@thinknimble/tn-utils'
import AxiosClient from '../AxiosClient'
import { apiErrorHandler } from '../api'

const OPPORTUNITIES_ENDPOINT = '/gtm/opportunities/'

export default class OpportunityAPI extends ModelAPI {
  get client() {
    return AxiosClient
  }

  static ENDPOINT = OPPORTUNITIES_ENDPOINT

  static FILTERS_MAP = {
    ...OpportunityAPI.FILTERS_MAP,
    // Pagination
    page: ApiFilter.create({ key: 'page' }),
    pageSize: ApiFilter.create({ key: 'page_size' }),
    // Sorting
    ordering: ApiFilter.create({ key: 'ordering' }),
    // filters
    friendlyIntroSent: ApiFilter.create({ key: 'friendly_intro_sent' }),
    adminIntroSent: ApiFilter.create({ key: 'admin_intro_sent' }),
    founderSelected: ApiFilter.create({ key: 'founder_selected' }),
    adminId: ApiFilter.create({ key: 'admin_user_id' }),
    isAdminOwned: ApiFilter.create({ key: 'is_admin_owned' }),
    friendlyId: ApiFilter.create({ key: 'friendly_id' }),
    adminDeclined: ApiFilter.create({ key: 'admin_declined' }),
    friendlyDeclined: ApiFilter.create({ key: 'friendly_declined' }),
    friendlyDoesNotKnow: ApiFilter.create({ key: 'friendly_does_not_know' }),
    excludeFounderRejected: ApiFilter.create({ key: 'exclude_founder_rejected' }),
    include: ApiFilter.create({ key: 'include' }),
    listId: ApiFilter.create({ key: 'salesforce_gtm_customer_list_member__gtm_customer_list_id' }),
  }

  retrieve(id) {
    const url = `${OPPORTUNITIES_ENDPOINT}${id}/`
    return this.client
      .get(url)
      .then((response) => this.cls.fromAPI(response.data))
      .catch(
        apiErrorHandler({
          apiName: 'OpportunityAPI.retrieve',
        }),
      )
  }

  retrieveByCustomerListMemberId(customerListMemberId) {
    const url = `${OPPORTUNITIES_ENDPOINT}by-customer-list-member/?customer_list_member_id=${customerListMemberId}`
    return this.client
      .get(url)
      .then((response) => this.cls.fromAPI(response.data))
      .catch(
        apiErrorHandler({
          apiName: 'OpportunityAPI.retrieveByCustomerListMemberId',
        }),
      )
  }

  retrieveAllOutstanding() {
    const url = `${OPPORTUNITIES_ENDPOINT}outstanding/`
    return this.client
      .get(url)
      .then((response) => response.data.map((x) => objectToCamelCase(x)))
      .catch(
        apiErrorHandler({
          apiName: 'OpportunityAPI.retrieveAllOutstanding',
        }),
      )
  }

  update(id, data) {
    const url = `${OPPORTUNITIES_ENDPOINT}${id}/`
    return this.client
      .patch(url, this.cls.toAPI(data))
      .then((response) => this.cls.fromAPI(response.data))
      .catch(
        apiErrorHandler({
          apiName: 'OpportunityAPI.update',
        }),
      )
  }

  bulkUpdate(opportunityIds, data) {
    const url = `${OPPORTUNITIES_ENDPOINT}bulk-update/`
    return this.client
      .patch(url, this.cls.toAPI({ opportunityIds, ...data }))
      .then((response) => this.cls.fromAPI(response.data))
      .catch(
        apiErrorHandler({
          apiName: 'OpportunityAPI.bulkUpdate',
        }),
      )
  }

  bulkAssignFriendly(opportunityIds, friendlyId) {
    const url = `${OPPORTUNITIES_ENDPOINT}bulk-assign-friendly/`
    const data = { opportunityIds, friendlyId }
    return this.client
      .post(url, objectToSnakeCase(data))
      .then((response) => response.data)
      .catch(
        apiErrorHandler({
          apiName: 'OpportunityAPI.bulkAssignFriendly',
        }),
      )
  }

  sendEmail(id, subject, body) {
    const data = { subject, body }
    const url = `${OPPORTUNITIES_ENDPOINT}${id}/send-email/`
    return this.client
      .post(url, this.cls.toAPI(data))
      .then((response) => this.cls.fromAPI(response.data))
      .catch(
        apiErrorHandler({
          apiName: 'OpportunityAPI.sendEmail',
        }),
      )
  }

  offloadAsGmailDraft(id) {
    const url = `${OPPORTUNITIES_ENDPOINT}${id}/offload-as-gmail-draft/`
    return this.client.post(url).catch(
      apiErrorHandler({
        apiName: 'OpportunityAPI.offloadAsGmailDraft',
      }),
    )
  }

  deleteOpportunityAndListMember(opportunityId) {
    const url = `${OPPORTUNITIES_ENDPOINT}${opportunityId}/delete-opportunity-and-list-member/`
    return this.client
      .post(url)
      .then((response) => response.data)
      .catch(
        apiErrorHandler({
          apiName: 'OpportunityAPI.deleteOpportunityAndListMember',
        }),
      )
  }

  outstandingInListForUser(customerListId, page = 1, pageSize = 20) {
    const url = `${OPPORTUNITIES_ENDPOINT}outstanding-in-list-for-user/`
    const params = objectToSnakeCase({ customerListId, page, pageSize })
    return this.client
      .get(url, { params })
      .then((response) => response.data.map((o) => this.cls.fromAPI(o)))
      .catch(
        apiErrorHandler({
          apiName: 'OpportunityAPI.outstandingInListForUser',
        }),
      )
  }
}

<template>
  <Modal @close="handleClose" classes="max-w-5xl">
    <div v-if="isEditing">
      <EditEmailTemplateModal
        :template="localTemplate"
        :cta-text="'Save Changes'"
        @close="toggleEditMode"
        @cta-click="updateTemplate"
      />
    </div>
    <div v-else>
      <h1 class="heading font-bold self-center">Preview Emails.</h1>
      <LoadingSpinner v-if="loading" />
      <template v-else>
        <Button primary classes="mt-8 self-center" @click.prevent="handleSend">
          {{ ctaText }}
        </Button>
        <EmailPreview
          v-for="(preview, idx) in previews"
          :key="idx"
          :to="opportunities[idx].contactEmail"
          :subject="preview.subject"
          :body="preview.body"
        />
        <Button primary classes="mt-10 self-center" @click.prevent="handleSend">
          {{ ctaText }}
        </Button>
        <Button v-if="editable" secondary classes="mt-4 self-center" @click="toggleEditMode">
          Edit Email Template
        </Button>
      </template>
    </div>
  </Modal>
</template>

<script>
import Opportunity from '@/services/opportunities'
import OpportunityEmailTemplate from '@/services/opportunityEmailTemplates'

import Modal from '@/components/modals/Modal'
import LoadingSpinner from '@/components/LoadingSpinner'
import EmailPreview from '@/components/EmailPreview'
import Button from '@/components/inputs/Button'
import EditEmailTemplateModal from '@/components/modals/EditEmailTemplateModal'

export default {
  name: 'PreviewEmailsModal',
  components: {
    Modal,
    LoadingSpinner,
    EmailPreview,
    Button,
    EditEmailTemplateModal,
  },
  props: {
    ctaText: {
      type: String,
      default: 'Send',
    },
    template: {
      type: OpportunityEmailTemplate,
      required: false,
    },
    opportunities: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every((opportunity) => opportunity instanceof Opportunity)
      },
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      previews: [],
      isEditing: false,
      localTemplate: {
        id: this.template?.id || null,
        subject: this.template?.subject || '',
        body: this.template?.body || '',
      },
    }
  },
  async created() {
    if (this.template) {
      await this.retrievePreviewsWithTemplateOverride()
    } else {
      await this.retrievePreviewsWithOpportunityTemplates()
    }
    this.loading = false
  },
  methods: {
    async retrievePreviewsWithTemplateOverride() {
      const promises = this.opportunities.map((o) =>
        OpportunityEmailTemplate.api.preview({
          opportunityId: o.id,
          emailSubject: this.localTemplate.subject,
          emailBody: this.localTemplate.body,
        }),
      )
      this.previews = await Promise.all(promises)
    },
    async retrievePreviewsWithOpportunityTemplates() {
      const promises = this.opportunities.map((o) =>
        OpportunityEmailTemplate.api.preview({
          opportunityId: o.id,
          emailSubject: o.friendlyIntroEmailTemplate.subject,
          emailBody: o.friendlyIntroEmailTemplate.body,
        }),
      )
      this.previews = await Promise.all(promises)
    },
    toggleEditMode() {
      this.isEditing = !this.isEditing
    },
    updateTemplate(newData) {
      this.localTemplate.subject = newData.subject
      this.localTemplate.body = newData.body
      this.retrievePreviewsWithTemplateOverride()
      this.isEditing = false
    },
    handleSend() {
      // Use the latest content from localTemplate to ensure the current data is used
      const dataToSend = {
        subject: this.localTemplate.subject,
        body: this.localTemplate.body,
      }
      // Emit the latest template to parent
      this.$emit('cta-click', dataToSend)
    },
    handleClose() {
      if (this.isEditing) {
        this.isEditing = false
      } else {
        this.$emit('close')
      }
    },
  },
}
</script>
